import { React, useContext } from 'react';

import * as logo from '../../assets/images/logo_white_200.png'
import * as title from '../../assets/images/title.png'

import { SidebarContext } from "../../context/SidebarContext.js";

const logoImg = logo.default;
const titleImg = title.default;

export default function Topbar() {
    const { toggleSidebar } = useContext(SidebarContext);

    return (
        <div id="topbar" className="md:hidden h-14 w-full bg-teal-800 flex flex-row justify-between select-none">
            <div className="flex flex-row text-white justify-start items-center my-auto ms-4">
                <img src={logoImg} alt="logo"className="logo-image mr-2 py-1"/>
                <img src={titleImg} alt="title" className="title-image ps-2 h-6"/>
            </div>
            <button id="mobile-sidebar-toggle" className="bg-teal-800 p-2 my-auto ml-2 text-gray-100" onClick={toggleSidebar}>
                <span className="bar-top"></span>
                <span className="bar-mid"></span>
                <span className="bar-bot"></span>
            </button>
        </div>   
    )
}