import * as logo from '../../assets/images/logo_white_200.png'

const img = logo.default;

export default function SidebarCollapsed ({ toggleSidebar }) {
    return (
        <div id="sidebar-collapsed-content" className="bg-teal-800 flex flex-col h-full w-full select-none">
            <img src={img} alt="logo" className="logo-image mx-auto mt-3"/>
            <div id="sidebar-toggle" className="collapsed bg-teal-800 py-2 text-gray-100 hidden md:block" onClick={toggleSidebar}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" className="w-4 h-4"><path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" /></svg>
            </div>
        </div>
    )
}