import { React, useContext, useState } from 'react';
import { ButtonTeal } from "../ui/Buttons.js"
import InputField from "../ui/Input.js";
import { useToast } from "../ui/shadcn/use-toast.tsx"

import { AppContext } from '../../context/AppContext.js';
import { AuthContext } from '../../context/AuthContext.js';
import { PinContext } from "../../context/PinContext.js";
import { SidebarContext } from "../../context/SidebarContext.js";

export default function LoginForm() {
    const { debug, apiURL, isResponseOk } = useContext(AppContext);
    const { csrf, getCSRF, setAuthActiveElement, handleAuthenticationChange } = useContext(AuthContext);
    const { getPins } = useContext(PinContext);
    const { setActiveScreen, toggleSidebar } = useContext(SidebarContext);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const { toast } = useToast();
    
    const login = (event) => {
        event.preventDefault();
        if (username === "" || password === "") {
            setError("Username and password required.");
            return;
        }
        fetch(`${apiURL}/login/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf,
            },
            credentials: "include",
            body: JSON.stringify({username: username, password: password}),
        })
        .then(isResponseOk)
        .then((data) => {
            if (debug) {
                console.log(data);
            }
            handleAuthenticationChange(true, data.currentUser);
            setUsername("");
            setPassword("");
            setError("");
            if (window.innerWidth < 768) {
                toggleSidebar();
            }
            getPins(data.currentUser);
            getCSRF();
            setActiveScreen("PinScreen");
            toast({
                title: `Successfully logged in as ${data.currentUser}.`,
            });
        })
        .catch((err) => {
            console.log(err);
            setError("Incorrect login details - try again.");
        });
    }

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const handleRegisterClick = () => {
        setUsername("");
        setPassword("");
        setError("");
        setAuthActiveElement("RegisterForm");
    }

    return (
        <div id="login-form" className="p-6 my-auto">       
            <div className="mb-4 text-center block text-2xl text-teal-900 font-extrabold cursor-default select-none">Log In</div>
            <form className="flex flex-col" onSubmit={login}>
                <InputField
                    label="Username"
                    id="username"
                    value={username}
                    maxLength="18"
                    onChange={handleUsernameChange}
                    className="mb-3"
                />
                <InputField
                    label="Password"
                    id="password"
                    value={password}
                    onChange={handlePasswordChange}
                    type="password"
                    className="mb-3"
                />
                {error && <div className="mb-4 text-center font-semibold text-red-500 text-lg leading-none">{error}</div>}
                <ButtonTeal outerClass="mx-auto w-28 text-lg leading-5" buttonText="Log In" buttonType="submit" />
            </form>
            <div className="pt-4 text-center text-gray-700 text-lg leading-none font-extrabold cursor-default select-none">
                Need an account?
                <button className="text-lg leading-none ps-1 underline" onClick={handleRegisterClick}>Sign Up</button>
            </div>
        </div>
    );
}