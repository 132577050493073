import { React, useContext } from 'react';

import { AppContext } from '../../context/AppContext';
import { AuthContext } from '../../context/AuthContext';
import { PinContext } from '../../context/PinContext';
import { SidebarContext } from '../../context/SidebarContext.js';

export default function AuthFooter() {
    const { debug, apiURL, isResponseOk } = useContext(AppContext);
    const { isAuthenticated, currentUser, setAuthActiveElement, getCSRF, handleAuthenticationChange } = useContext(AuthContext);
    const { removeMarkers, removePopups } = useContext(PinContext);
    const { setActiveScreen } = useContext(SidebarContext);

    function logout() {
        fetch(`${apiURL}/logout`, {
            credentials: "include",
        })
        .then(isResponseOk)
        .then((data) => {
            if (debug) {
                console.log(data);
            }
            handleAuthenticationChange(false, "");
            getCSRF();
            setAuthActiveElement("LoginForm");
            removeMarkers({ clearState: true });
            removePopups({ clearState: true });
            setActiveScreen("AuthScreen");
        })
        .catch((err) => {
            console.log(err);
        });
    };

    return (
        <div id="auth" className="diagonal mt-auto px-4 flex content-end cursor-default select-none">
            {isAuthenticated ? (
                <div className="diagonal-content flex flex-row w-full justify-between mt-auto">
                    <div className="mt-2 text-teal-200 cursor-default">
                        Logged in as
                        <span className="font-semibold display:inline"> {currentUser}</span>
                    </div>
                    <button className="mt-2 text-teal-200 hover:text-white" onClick={logout}>Logout</button>
                </div>
            ) : (<div className="py-7"></div>)}
        </div>
    )
}
