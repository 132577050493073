import { React, useContext, useState } from "react";
import { useToast } from "..//ui/shadcn/use-toast.tsx"

import { ButtonTeal, PlusButton } from "../ui/Buttons.js"
import InputField from "../ui/Input.js";

import { AppContext } from '../../context/AppContext.js';
import { AuthContext } from '../../context/AuthContext.js';
import { MapContext } from '../../context/MapContext.js';
import { PinContext } from '../../context/PinContext.js';
import { SidebarContext } from '../../context/SidebarContext.js';

export default function PinForm({ edit }) {
    const { debug, apiURL, isResponseOk } = useContext(AppContext);
    const { currentUser, csrf } = useContext(AuthContext);
    const { lat, lng } = useContext(MapContext);
    const { getPins, pinInstance, removePopups, displayPinDetail } = useContext(PinContext);
    const { toggleSidebar } = useContext(SidebarContext);

    const [name, setName] = useState(() => (
        edit === false ? ("") : (pinInstance.properties.name)
    ));
    const [description, setDescription] = useState(() => (
        edit === false ? ("") : (pinInstance.properties.description)
    ));
    const [tags, setTags] = useState(() => (
        edit === false ? ([]) : (pinInstance.properties.tags)
    ));
    const [tag, setTag] = useState("");
    const [visited, setVisited] = useState(() => (
        edit === false ? (false) : (pinInstance.properties.visited)
    ));
    const [error, setError] = useState("");

    const { toast } = useToast();

    const handleNameChange = (event) => {
        setName(event.target.value);
    }

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    }

    const handleTagChange = (event) => {
        setTag(event.target.value.replace(/\s+/g, ""));
    }

    const handleVisitedChange = (event) => {
        setVisited(event.target.checked);
    }

    const submitPinForm = (event) => {
        event.preventDefault();
        if (name === "") {
            setError("Add a name for your pin.");
            return;
        }

        var url = `${apiURL}/pins/`;
        var requestType = "POST";
        var body = {
            name: name,
            description: description,
            lat: lat,
            lng: lng,
            tags: tags,
            visited: visited,
            username: currentUser
        }
        
        if (edit) {
            requestType = "PUT";
            url += pinInstance.properties.pk += "/";
            body.lat = pinInstance.geometry.coordinates[1];
            body.lng = pinInstance.geometry.coordinates[0];
        }

        if (debug) {
            console.log(`${requestType}ing pin`, body);
            console.log({"csrf": csrf});
        }

        fetch(url, {
            method: requestType,
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf,
            },
            credentials: "include",
            body: JSON.stringify(body),
        })
        .then(isResponseOk)
        .then((data) => {
            if (debug) { console.log({"Response": data}); }
            var toastTitle = "Added a pin";
            if (edit) { toastTitle = "Edited a pin"; }
            toast({
                title: toastTitle,
                description: name
            });
            setName("");
            setDescription("");
            setTags([]);
            setVisited(false);
            setError("");
            if (!edit) { removePopups({ clearState: false }); }
            getPins(currentUser, data.id);
            if (window.innerWidth < 768) { toggleSidebar(); }
            else { displayPinDetail(); }
        })
        .catch((error) => {
            console.error(error);
        });
    }

    const addTag = () => {
        if (debug) {
            console.log("Adding tag: " + tag);
        }
        if (tag === "" || tags.includes(tag)) {
            setTag("");
            return;
        }
        else {
            setTags(tags => [...tags, tag.replace(/\s+/g, "")]);
            setTag("");
        }
    }

    const removeTag = (tag) => {
        if (debug) {
            console.log("Removing tag: " + tag);
        }
        setTags(tags.filter(item => item !== tag));
    }

    return (
        <div id="pin-form" className="my-auto p-6">
            <div className="mb-4 text-center block text-2xl text-teal-900 font-extrabold cursor-default select-none">
                { edit === false ? ("Add a Pin") : ("Edit Pin") }
            </div>
            <form className="flex flex-col" onSubmit={ submitPinForm }>
                <InputField
                    label="Name"
                    id="name"
                    value={name}
                    maxLength="50"
                    onChange={handleNameChange}
                    className="mb-4"
                />
                <InputField
                    label="Description"
                    id="description"
                    value={description}
                    maxLength="500"
                    onChange={handleDescriptionChange}
                    className="mb-4"
                />
                <div className="mb-3">
                    <label className="block text-gray-700 text-lg leading-none font-extrabold mb-2 cursor-default select-none" htmlFor="tag">
                        Add Tag
                    </label>
                    <div className="flex flex-row">
                        <InputField
                            id="tag"
                            value={tag}
                            maxLength="30"
                            onChange={handleTagChange}
                            onKeyDown={(e) => {
                                if (e.key === " " || e.key === "Enter") e.preventDefault();
                                if (e.key === "Enter") addTag();
                            }}
                            className="w-full pe-3"
                        />
                        <div className="mb-4">
                            <PlusButton buttonType="button" onClick={addTag} />
                        </div>
                    </div>
                    {tags.length > 0 && (
                        <div className="mb-4 flex flex-wrap justify-start text-lg leading-none text-emerald-100 font-semibold">
                            {tags.map((tag) => (
                                <button
                                    id="tag-button"
                                    className="p-1 me-1 mb-1 flex flex-row bg-teal-700 rounded"
                                    key={tag}
                                    type="button"
                                    title="Delete tag"
                                    onClick={() => removeTag(tag)}
                                >
                                    <div className="mx-1 my-auto text-md font-medium">{tag}</div>
                                    <div id="tag-remove-button">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </div>
                                </button>
                            ))}
                        </div>
                    )}
                </div>
                { edit === false &&
                    <InputField
                        label="Coordinates"
                        id="coordinates"
                        value={`${lat} ${lng}`}
                        readOnly={true}
                        inputClassName="cursor-default"
                        className="mb-3"
                    />
                }
                {/* TODO - Componentise */}
                <div className={"flex flex-row h-8 mb-5"}>
                    <label className={"block text-gray-700 text-lg leading-none font-extrabold select-none cursor-pointer checkbox-container pe-10 pt-2"} htmlFor={"visited"}>
                        Location Visited
                        <input
                            id="visited"
                            type="checkbox"
                            checked={visited}
                            onChange={handleVisitedChange}
                        />
                        <span className={"checkmark"}></span>
                    </label>
                </div>
                {error && <div className="mb-4 text-center font-semibold text-red-500 text-lg leading-none">{error}</div>}
                <div className="flex flex-row justify-center">
                    <ButtonTeal outerClass="mx-auto w-24 text-lg leading-5" buttonText="Save" buttonType="submit" />
                    { edit === true &&
                        <ButtonTeal outerClass="mx-auto w-24 text-lg leading-5" buttonText="Cancel" buttonType="button" onClick={displayPinDetail}/>
                    }
                </div>
            </form>
        </div>
    );
}