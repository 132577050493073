import React, { createContext, useContext, useState } from 'react';
import { MapContext } from './MapContext';

export const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
    const [showSidebarLoader, setShowSidebarLoader] = useState(false);
    const [activeScreen, setActiveScreen] = useState('AuthScreen');

    const { map } = useContext(MapContext);

    const toggleSidebar = () => {
        // If sidebar has class 'collapsed' at the start, it is opening
        var sidebar = document.getElementById('sidebar')
        var direction;
        if (sidebar.classList.contains('collapsed')) {
            direction = "opening";
        } else {
            direction = "closing";
        }
        sidebar.classList.add(direction);
        setTimeout(() => {
            sidebar.classList.remove(direction);
        }, 520);

        sidebar.classList.toggle('collapsed');
        document.getElementById('mobile-sidebar-toggle').classList.toggle('opened');
        document.getElementById('map').classList.toggle('hide-controls');
        if (window.innerWidth >= 768) {
            setTimeout(() => {
                handleSidebarWidthChange();
            }, 120);
        } else {
            toggleMobileHidden('custom-controls');
            toggleMobileHidden('crosshair');
        }
    };

    function toggleMobileHidden(elementID) {
        document.getElementById(elementID).classList.toggle('hidden');
        document.getElementById(elementID).classList.toggle('md:flex');
    };

    function handleSidebarWidthChange() {
        const sidebar = document.getElementById('sidebar');
        const sidebarContent = document.getElementById('sidebar-content');
        const crosshair = document.getElementById('crosshair');
        var sidebarWidth;
        var windowWidth = window.innerWidth;

        // Calculate sidebar width if open, otherwise use 60px
        if (sidebar.classList.contains('collapsed')) {
            sidebarWidth = 60;
        } else {
            sidebarWidth = sidebarContent.offsetWidth;
        }

        // Set auth footer width to match sidebar
        document.getElementById('auth').style.setProperty('--auth-width', `${sidebarContent.offsetWidth}px`);

        if (windowWidth > 767) {
            // Set central crosshair position with a offset for the sidebar
            var offset = (windowWidth - sidebarWidth) / 2;
            crosshair.style.right = `calc(${offset}px - 19px)`;
            map.current.easeTo({padding: {left: sidebarWidth}});
        } else {
            crosshair.style.right = `${(windowWidth / 2) - 19}px`;
            map.current.easeTo({padding: {left: 0}});
        }
    };

    return (
        <SidebarContext.Provider value={{
            showSidebarLoader, setShowSidebarLoader,
            activeScreen, setActiveScreen,
            toggleSidebar,
            handleSidebarWidthChange,
        }}>
            {children}
        </SidebarContext.Provider>
    );
};