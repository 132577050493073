import React, { createContext, useContext, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { useToast } from '../components//ui/shadcn/use-toast.tsx';
import { ToastAction } from '../components//ui/shadcn/toast.tsx';

import { AppContext } from './AppContext';
import { AuthContext } from './AuthContext';
import { MapContext } from './MapContext.js';
import { SidebarContext } from './SidebarContext.js';

export const PinContext = createContext();

export const PinProvider = ({ children }) => {
    const { toast } = useToast();
    const emptyPin = {geometry: {coordinates: []}, properties: {pk: 0, name: "", description: "", tags: []}};

    const [markers, setMarkers] = useState({});
    const [popups, setPopups] = useState({});
    const [pinInstance, setPinInstance] = useState(emptyPin);
    const [pinActiveElement, setPinActiveElement] = useState("PinForm");
    const [showPinDelete, setShowPinDelete] = useState(false);

    const { debug, apiURL, isResponseOk } = useContext(AppContext);
    const { currentUser, csrf } = useContext(AuthContext);
    const { map } = useContext(MapContext);
    const { setActiveScreen, toggleSidebar } = useContext(SidebarContext);

    function getPins(user, id) {
        if (!user && !currentUser) {return;}
        if (currentUser) {user = currentUser;}
        if (debug) {
            if (!id) {
                console.log("Getting pins for: " + user);
            } else {
                console.log("Getting pin " + id);
            }
        }
    
        var url = `${apiURL}/pins/?created_by=${user}`;
        if (id) {url += `&id=${id}`;}
    
        fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf,
            },
            credentials: "include",
        })
        .then(isResponseOk)
        .then((data) => {
            data.features.map((feature) => {
                addMarker(feature, id);
            });
        })
        .catch(error => {
            console.error('Error during getPins:', error);
        });
    }

    function addMarker (feature, id) {
        if (debug) {console.log("Adding marker: ", feature);}
    
        const popup = createPopup(feature);
        const marker = document.createElement('div');
        var pinClassName = 'pin';
    
        if (feature.properties.visited) { pinClassName += ' visited'; }    
        marker.className = pinClassName;
    
        new mapboxgl.Marker({
            element: marker,
            offset: [-1, -17]
        }).setLngLat(feature.geometry.coordinates).setPopup(popup).addTo(map.current);

        if (id) {
            popup.addTo(map.current); // open new popup
            setPinInstance(feature); // set the new pin instance

            if (markers[id]) {
                markers[id].remove(); // remove the old marker
            }
            if (popups[id]) {
                popups[id].remove(); // remove the old popup
            }
        }

        var updatedMarkers = markers;
        updatedMarkers[feature.properties.pk] = marker;
        setMarkers(updatedMarkers);

        var updatedPopups = popups;
        updatedPopups[feature.properties.pk] = popup;
        setPopups(updatedPopups);
    
    }

    function deleteMarker(pk) {
        if (debug) {console.log("Deleting marker " + pk);}

        if (markers[pk]) {
            var updatedMarkers = markers;
            updatedMarkers[pk].remove();
            delete updatedMarkers[pk];
            setMarkers(updatedMarkers);
        }
    }
    
    function deletePopup(pk) {
        if (debug) {console.log("Deleting popup for marker " + pk);}

        if (popups[pk]) {
            var updatedPopups = popups;
            updatedPopups[pk].remove();
            delete updatedPopups[pk];
            setPopups(updatedPopups);
        }
    }

    function createPopup(feature) {
        const popup = new mapboxgl.Popup({ offset: [-1, -35] })
        .on('open', () => {
            const pinDetailBtn = document.getElementById('pin-detail-btn');
            if (pinDetailBtn) {
                pinDetailBtn.addEventListener(
                    'click', function(){
                        displayPinDetail(feature);
                    }
                );
                if (debug) {console.log({'Popup opened': feature});}
            }
        })
        .on('close', () => {
            if (debug) {console.log({'Popup closed': feature});}
        });
    
        var popupContent = `
            <button id="pin-detail-btn" class="absolute top-0 left-0">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="#404040" class="h-4 w-4">
                    <path fill-rule="evenodd" d="M14 4.75A2.75 2.75 0 0 0 11.25 2h-3A2.75 2.75 0 0 0 5.5 4.75v.5a.75.75 0 0 0 1.5 0v-.5c0-.69.56-1.25 1.25-1.25h3c.69 0 1.25.56 1.25 1.25v6.5c0 .69-.56 1.25-1.25 1.25h-3c-.69 0-1.25-.56-1.25-1.25v-.5a.75.75 0 0 0-1.5 0v.5A2.75 2.75 0 0 0 8.25 14h3A2.75 2.75 0 0 0 14 11.25v-6.5Zm-9.47.47a.75.75 0 0 0-1.06 0L1.22 7.47a.75.75 0 0 0 0 1.06l2.25 2.25a.75.75 0 1 0 1.06-1.06l-.97-.97h7.19a.75.75 0 0 0 0-1.5H3.56l.97-.97a.75.75 0 0 0 0-1.06Z" clip-rule="evenodd" />
                </svg>
            </button>
            <h3 class="text-lg leading-none text-teal-800 break-words font-extrabold p-2 px-6">
                ${feature.properties.name}
            </h3>
        `;
    
        if (feature.properties.tags && feature.properties.tags.length > 0) {
            popupContent += '<div class="flex flex-row justify-center leading-none pb-1">';
            feature.properties.tags.map(tag => {
                popupContent += `<span class="popup-tag text-emerald-100 bg-teal-700">${tag}</span>`;
            });
            popupContent += '</div>';
        }
    
        popup.setHTML(popupContent);
        return popup;
    };

    function removeMarkers({ clearState }) {
        if (debug) { console.log("Clearing markers"); }

        for (var marker of Object.values(markers)) {
            marker.remove();
        }
        if (clearState)  {setMarkers({}); }
    }

    function removePopups({ clearState }) {
        if (debug) { console.log("Clearing popups"); }

        for (var popup of Object.values(popups)) {
            popup.remove();
        }
        if (clearState) {setPopups({}); }
    }

    function displayPinDetail(pin) {
        if (pin && !(pin._reactName)) { setPinInstance(pin); }
        else if (pinInstance)  {pin = pinInstance; }
        else { return; }

        if (debug) { console.log("Displaying pin detail for:", pin); }
        
        setActiveScreen("PinScreen");
        setPinActiveElement("PinDetail");
        if (window.innerWidth < 768) { toggleSidebar(); }
    }

    const deletePin = () => {
        var pin = pinInstance;
        if (debug) {
            console.log("Deleting pin " + pin.properties.pk);
        }
        fetch(`${apiURL}/pins/${pin.properties.pk}/`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf,
            },
            credentials: "include",
        })
        .then(deletePinResponse)
        .catch((error) => {
            console.error(error);
        });
    }

    const deletePinResponse = (response) => {
        var pin = pinInstance;
        if ((response.status >= 200 && response.status <= 299) || response.status == 404) {
            deleteMarker(pin.properties.pk);
            deletePopup(pin.properties.pk);
            setPinInstance(emptyPin);
            toast({
                title: "Deleted a pin",
                description: pin.properties.name,
                action: <ToastAction altText="Undo">Undo</ToastAction>,
            });
            if (window.innerWidth < 768) {
                toggleSidebar();
            }
            setShowPinDelete(false);
            setPinActiveElement("PinForm");
        } else {
            throw Error(response.status + ' ' + response.statusText);
        }
    }

    useEffect(() => {
        if (debug) {
            console.log("pinActiveElement changed to:", pinActiveElement);
        }
    }, [pinActiveElement]);

    useEffect(() => {
        if (debug) {
            console.log("pinInstance changed to:", pinInstance);
        }
    }, [pinInstance]);

    return (
        <PinContext.Provider value={{
            getPins,
            pinInstance,
            pinActiveElement, setPinActiveElement,
            removeMarkers, removePopups,
            displayPinDetail,
            deletePin,
            showPinDelete, setShowPinDelete,
        }}>
            {children}
        </PinContext.Provider>
    );
};