import React, { createContext, useRef, useState } from 'react';

export const MapContext = createContext();

export const MapProvider = ({ children }) => {
    const map = useRef(null);
    const mapContainer = useRef(null);
    const [lat, setLat] = useState(53.71);
    const [lng, setLng] = useState(-4.79);
    const [zoom, setZoom] = useState(4.8);

    return (
        <MapContext.Provider value={{
            map,
            mapContainer,
            lat, setLat,
            lng, setLng,
            zoom, setZoom
        }}>
            {children}
        </MapContext.Provider>
    );
};