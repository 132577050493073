import { React, useContext, useEffect } from 'react';

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import 'mapbox-gl/dist/mapbox-gl.css';
import '../assets/styles/mapbox.css';

import Sidebar from "./Sidebar";
import { AppContext } from '../context/AppContext.js';
import { MapContext } from '../context/MapContext.js';
import { SidebarContext } from '../context/SidebarContext.js';

mapboxgl.accessToken = "pk.eyJ1Ijoiam9lZGF2aXNvbiIsImEiOiJjbGpyNnh1bGwwMWJpM2RtdWVudGtxdHIzIn0.xPIegcKezYE_Qh7ki276ug";

export default function Map () {
    const { appLoaded, setShowPageLoader } = useContext(AppContext);
    const { map, mapContainer, lat, setLat, lng, setLng, zoom, setZoom } = useContext(MapContext);
    const { handleSidebarWidthChange } = useContext(SidebarContext);

    useEffect(() => {
        if (map.current) return;
        if (window.innerWidth < 768) {
            document.getElementById('sidebar').classList.add('collapsed');
            document.getElementById('sidebar-toggle').classList.add('collapsed');
        }
        // Initialise map
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            projection: 'globe',
            style: 'mapbox://styles/joedavison/clk17isgp00b401pf9fse6li6',
            center: [lng, lat],
            zoom: zoom,
            maxPitch: 0,
        });
        // Add map controls
        map.current.addControl(new mapboxgl.NavigationControl());
        initialiseGeocoder(map);
        handleSidebarWidthChange();
    });

    useEffect(() => {
        if (!map.current || !appLoaded) return;
        // Runs after map initialised
        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });
        setTimeout(() => {
            setShowPageLoader(false);
        }, 500);
    });

    function initialiseGeocoder(map) {
        map.current.addControl(
            new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                mapboxgl: mapboxgl,
                placeholder: 'Search for a location...',
                minLength: 3,
                enableEventLogging: false,
                marker: false,
                zoom: 12,
                flyTo: {curve: 2, speed: 1.2}
            })
        );
        document.getElementsByClassName('mapboxgl-ctrl-geocoder')[0].classList.add('show');
        document.getElementsByClassName('mapboxgl-ctrl-geocoder--input')[0].setAttribute('spellcheck', 'false');
    }

    const toggleCrosshair = () => {
        document.getElementById('crosshair').classList.toggle('hidden');
        document.getElementById('crosshair-toggle').classList.toggle('toggle-active');
    }

    const toggleMarkers = () => {
        document.getElementById('markers-toggle').classList.toggle('toggle-active');
        var markers = document.getElementsByClassName('mapboxgl-marker');
        var popups = document.getElementsByClassName('mapboxgl-popup');
        for (let i = 0; i < markers.length; i++) {
            markers[i].classList.toggle('pin-hidden');
        }
        for (let i = 0; i < popups.length; i++) {
            popups[i].classList.toggle('pin-hidden');
        }
    }

    const toggleGeocoder = () => {
        document.getElementsByClassName('mapboxgl-ctrl-geocoder')[0].classList.toggle('show');
        document.getElementById('geocoder-toggle').classList.toggle('toggle-active');
    }

    return (
        <div id="map" className="h-full w-full relative">
            <div ref={mapContainer} className="map-container h-full"/>
            <svg id="crosshair" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="#454545" strokeWidth="0.75" strokeLinecap="square" strokeLinejoin="square" className="lucide lucide-plus"><path d="M5 12h14"/><path d="M12 5v14"/></svg>
            <Sidebar />
            <div id="custom-controls" className="controls flex flex-col">     
                <button className="control control-top" id="crosshair-toggle" type="button" aria-label="Toggle crosshair" aria-disabled="false" title="Toggle crosshair" onClick={toggleCrosshair}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 24 24" fill="none" stroke="#454545" strokeWidth="1.25" strokeLinecap="square" strokeLinejoin="square"><path d="M5 12h14"/><path d="M12 5v14"/></svg>
                </button>
                <button className="control" id="markers-toggle" type="button" aria-label="Toggle markers" aria-disabled="false" title="Toggle markers" onClick={toggleMarkers}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="25" viewBox="0 0 24 24" fill="var(--fill)" stroke="var(--stroke)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"><path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z"/><circle stroke="white" fill="white" cx="12" cy="10" r="3"/></svg>
                </button>
                <button className="control control-bottom" id="geocoder-toggle" type="button" aria-label="Toggle map search" aria-disabled="false" title="Toggle map search" onClick={toggleGeocoder}>
                    <svg viewBox="0 0 18 18" width="29" height="24" fill="#454545"><path d="M7.4 2.5c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9c1 0 1.8-.2 2.5-.8l3.7 3.7c.2.2.4.3.8.3.7 0 1.1-.4 1.1-1.1 0-.3-.1-.5-.3-.8L11.4 10c.4-.8.8-1.6.8-2.5.1-2.8-2.1-5-4.8-5zm0 1.6c1.8 0 3.2 1.4 3.2 3.2s-1.4 3.2-3.2 3.2-3.3-1.3-3.3-3.1 1.4-3.3 3.3-3.3z"></path></svg>
                </button>
            </div>
        </div>
    )
}
