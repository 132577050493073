import { React, useContext } from "react";

import { AppContext } from '../../context/AppContext';
import { AuthContext } from '../../context/AuthContext';
import { PinContext } from '../../context/PinContext';
import { SidebarContext } from "../../context/SidebarContext.js";

import { ButtonTeal } from "../ui/Buttons.js"

export default function ProfileScreen () {
    const { debug, apiURL, isResponseOk } = useContext(AppContext);
    const { isAuthenticated, currentUser, setAuthActiveElement, getCSRF, handleAuthenticationChange } = useContext(AuthContext);
    const { activeScreen, setActiveScreen } = useContext(SidebarContext);
    const { removeMarkers, removePopups } = useContext(PinContext);

    function logout() {
        fetch(`${apiURL}/logout`, {
            credentials: "include",
        })
        .then(isResponseOk)
        .then((data) => {
            if (debug) {
                console.log(data);
            }
            handleAuthenticationChange(false, "");
            getCSRF();
            setAuthActiveElement("LoginForm");
            removeMarkers({ clearState: true });
            removePopups({ clearState: true });
            setActiveScreen("AuthScreen");
        })
        .catch((err) => {
            console.log(err);
        });
    };


    return (
        <div id="profile-screen" className={activeScreen === "ProfileScreen" && "active"}>
            {isAuthenticated ? (
                <div className="flex flex-col w-full h-full items-center p-6">
                    <div className="my-4 text-center block text-2xl text-teal-900 font-extrabold cursor-default select-none">
                       Profile
                    </div>
                    <div className="flex flex-row justify-center items-center mt-12">
                        <div className="text-teal-900 cursor-default mx-4 mb-2 font-semibold">
                            Logged in as
                            <span className="font-extrabold display:inline"> {currentUser}</span>
                        </div>
                        <ButtonTeal outerClass="mx-4 w-26 text-lg leading-5" onClick={logout} buttonText="Logout" />
                    </div>
                </div>
            ) : (<div className="py-7"></div>)}
        </div>
    );
};
