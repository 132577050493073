import { React, useContext, useEffect } from "react";

import PinForm from "../Pin/PinForm.js";
import PinDetail from "../Pin/PinDetail.js";

import { AuthContext } from "../../context/AuthContext.js";
import { PinContext } from "../../context/PinContext.js";
import { SidebarContext } from "../../context/SidebarContext.js";

export default function PinScreen() {
    const { isAuthenticated, retrievedSession } = useContext(AuthContext);
    const { getPins, pinActiveElement } = useContext(PinContext);
    const { activeScreen, setActiveScreen } = useContext(SidebarContext);

    useEffect(() => {
        // Triggered when getSession returns an authenticated user on initial load
        if (isAuthenticated) {
            getPins();
            setActiveScreen("PinScreen");
        }
    }, [retrievedSession]);

    return (
        <div id="pin-screen" className={activeScreen === "PinScreen" && "active"}>
            { pinActiveElement === "PinForm" && <PinForm edit={false} /> }
            { pinActiveElement === "PinEditForm" && <PinForm edit={true} /> }
            { pinActiveElement === "PinDetail" && <PinDetail /> }
        </div>
    );
};