import { React, useContext } from "react";

import { SidebarContext } from "../../context/SidebarContext.js";

export default function ProfileScreen () {
    const { activeScreen } = useContext(SidebarContext);

    return (
        <div id="profile-screen" className={activeScreen === "ProfileScreen" && "active"}>
            <h1 className="m-auto text-center block text-2xl text-teal-900 font-extrabold cursor-default select-none">Coming Soon...</h1>
        </div>
    );
};
