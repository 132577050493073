import { React, useContext } from 'react';
import RegisterForm from '../Auth/RegisterForm';
import LoginForm from '../Auth/LoginForm';

import { AuthContext } from '../../context/AuthContext';
import { SidebarContext } from "../../context/SidebarContext.js";

export default function AuthScreen () {
    const { authActiveElement } = useContext(AuthContext);
    const { activeScreen } = useContext(SidebarContext);

    return (
        <div id="auth-screen" className={activeScreen === "AuthScreen" && "active"}>
            { authActiveElement == "LoginForm" && (
                <LoginForm />
            )}
            { authActiveElement == "RegisterForm" && (
                <RegisterForm />
            )}
        </div>
    );
};