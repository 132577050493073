import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.js";
import 'mapbox-gl/dist/mapbox-gl.css';
import "./assets/styles/index.css";

import { AppProvider } from "./context/AppContext.js";
import { AuthProvider } from "./context/AuthContext.js";
import { MapProvider } from "./context/MapContext.js";
import { PinProvider } from "./context/PinContext.js";
import { SidebarProvider } from "./context/SidebarContext.js";

ReactDOM.createRoot(document.getElementById('root')).render(
    <AppProvider>
        <AuthProvider>
            <MapProvider>
                <SidebarProvider>
                    <PinProvider>
                        <App />
                    </PinProvider>
                </SidebarProvider>
            </MapProvider>
        </AuthProvider>
    </AppProvider>
);