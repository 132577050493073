import { React, useContext, useEffect } from 'react';

import AuthScreen from './screens/AuthScreen.js';
import PinScreen from './screens/PinScreen.js';
import ProfileScreen from './screens/ProfileScreen.js';

import SidebarCollapsed from './Logo/SidebarCollapsed.js';
import SidebarDesktopLogo from './Logo/SidebarDesktopLogo.js';
import AuthFooter from './Auth/AuthFooter.js';
import { CompassLoader } from './ui/Loaders.js';

import { AppContext } from '../context/AppContext.js';
import { SidebarContext } from '../context/SidebarContext.js';

export default function Sidebar () {
    const { debug } = useContext(AppContext);
    const { showSidebarLoader, activeScreen, setActiveScreen, toggleSidebar } = useContext(SidebarContext);

    useEffect(() => {
        if (debug) { console.log("activeScreen changed to:", activeScreen); }
    }, [activeScreen]);

    return (
        <div id="sidebar" className="w-full md:w-6/12 lg:w-5/12 xl:w-4/12 2xl:w-3/12 flex flex-row">
            <SidebarCollapsed toggleSidebar={toggleSidebar}/>
            <div id="sidebar-content" className="flex flex-col h-full w-full bg-gradient-to-br from-green-300 via-emerald-200 to-emerald-300">
                <SidebarDesktopLogo/>
                {showSidebarLoader ? (
                    <CompassLoader/>
                ) : (
                    <>
                        <AuthScreen />
                        <PinScreen />
                        <ProfileScreen />
                    </>
                )}
                <AuthFooter />
                <div id="tab-container" className={ activeScreen === "AuthScreen" && "hidden" }>
                    <div
                        className={ activeScreen === "PinScreen" ? ("tab active") : ("tab") }
                        onClick={() => setActiveScreen("PinScreen")}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M20 10c0 4.993-5.539 10.193-7.399 11.799a1 1 0 0 1-1.202 0C9.539 20.193 4 14.993 4 10a8 8 0 0 1 16 0"/><circle cx="12" cy="10" r="3"/></svg>
                    </div>
                    <div
                        className={ activeScreen === "ProfileScreen" ? ("tab active") : ("tab") }
                        onClick={() => setActiveScreen("ProfileScreen")}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M18 20a6 6 0 0 0-12 0"/><circle cx="12" cy="10" r="4"/><circle cx="12" cy="12" r="10"/></svg>
                    </div>
                </div>
                <div id="sidebar-toggle" className="bg-teal-800 py-2 text-gray-100 hidden md:block" onClick={toggleSidebar}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" className="w-4 h-4"><path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" /></svg>
                </div>
            </div>
        </div>
    );
};