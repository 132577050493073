import * as logo from '../../assets/images/logo_white_200.png'
import * as title from '../../assets/images/title.png'

const logoImg = logo.default;
const titleImg = title.default;

export default function SidebarDesktopLogo() {
    return (
        <div className="hidden md:block bg-teal-800 p-6 select-none">
            <div className="flex flex-row text-white justify-center items-center">
                <img src={logoImg} alt="logo" className="logo-image mr-4 w-14"/>
                <img src={titleImg} alt="title" className="title-image w-56"/>
            </div>
        </div>
    )
}