import { React, useContext, useEffect } from "react";
import "./assets/styles/App.css";
import Map from "./components/Map";
import Topbar from "./components/ui/Topbar.js";
import { PageLoader } from "./components/ui/Loaders.js";
import { Toaster } from "./components/ui/shadcn/toaster.tsx"

import { AuthContext } from "./context/AuthContext";
import { SidebarContext } from "./context/SidebarContext.js";

export default function App() {
    const { healthCheck } = useContext(AuthContext);
    const { handleSidebarWidthChange } = useContext(SidebarContext);

    useEffect(() => {
        healthCheck(1);
     }, []); 
    
    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });
    
    var resizing = false;
    const handleWindowResize = () => {
        if (!resizing) {
            resizing = true;
            // When window has been resized, after a 300ms delay resize the map
            setTimeout(() => {
                handleSidebarWidthChange();
                resizing = false;
            }, 300);
        }
    }

    return (
        <>
            <PageLoader />
            <Topbar />                   
            <main className="flex flex-row h-full w-full">
                <Map />
            </main>
            <Toaster />
        </>

    );
}