import React, { createContext, useContext, useState } from 'react';

import { AppContext } from './AppContext';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const { setAppLoaded, debug, apiURL } = useContext(AppContext);

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [currentUser, setCurrentUser] = useState("");
    const [csrf, setCsrf] = useState("");
    const [retrievedSession, setRetrievedSession] = useState(false);
    const [authActiveElement, setAuthActiveElement] = useState("LoginForm");

    function healthCheck(attempts) {
        if (debug) {
            console.log(`Sending health check API call, attempts: ${attempts}`);
        }

        fetch(`${apiURL}/health/`)
        .then((res) => {
            if (debug) {
                console.log("API health check successful");
            }
            if (res.status == 200) {
                clearInterval();
                getSession();
                getCSRF();
            }
        }).catch((err) => {
            console.log(err);

            if (attempts < 6) {
                setTimeout(() => {
                    healthCheck(attempts + 1);
                }, 10000);
            } else {
                document.getElementById("loader-tagline").innerText =
                "The website appears to be down, please try again later..."
            }

            if (debug) {
                console.log("API health check failed");
            }
        });
    }

    function getCSRF() {
        if (debug) {
            console.log("Getting CSRF token");
        }
        fetch(`${apiURL}/csrf/`, {
            credentials: "include",
        })
        .then((res) => {
            let csrfToken = res.headers.get("X-CSRFToken");
            setCsrf(csrfToken);
            if (debug) {
                console.log('csrfToken: ' + csrfToken);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }

    function getSession() {
        if (debug) {
            console.log("Getting session");
        }
        fetch(`${apiURL}/session/`, {
            credentials: "include",
        })
        .then((res) => res.json())
        .then((data) => {
            if (debug) { console.log({"Get session response": data}); }
            if (data.isAuthenticated) {
                if (debug) { console.log("Authenticated"); }
                setIsAuthenticated(true);
                setCurrentUser(data.currentUser);
                setRetrievedSession(true);
            } else {
                if (debug) { console.log("Not authenticated"); }
                setIsAuthenticated(false);
                setCurrentUser("");
            }
            setAppLoaded(true);
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const handleAuthenticationChange = (isAuthenticated, username) => {
        setIsAuthenticated(isAuthenticated);
        setCurrentUser(username);
    }

    return (
        <AuthContext.Provider value={{
            isAuthenticated, setIsAuthenticated,
            currentUser, setCurrentUser,
            csrf, setCsrf,
            authActiveElement, setAuthActiveElement,
            retrievedSession,
            healthCheck,
            getCSRF,
            getSession,
            handleAuthenticationChange,
        }}>
            {children}
        </AuthContext.Provider>
    );
};