import React, { createContext, useState } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [appLoaded, setAppLoaded] = useState(false);
    const [showPageLoader, setShowPageLoader] = useState(true);
    const debug = (process.env.REACT_APP_DEBUG === "true");
    const apiURL = process.env.REACT_APP_API_URL;

    const isResponseOk = (response) => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            if (debug) {
                console.log(response.json());
                console.log(response);
            }
            throw Error(response.status + ' ' + response.statusText);
        }
    };

    return (
        <AppContext.Provider value={{
            appLoaded, setAppLoaded,
            showPageLoader, setShowPageLoader,
            debug,
            apiURL,
            isResponseOk,
        }}>
            {children}
        </AppContext.Provider>
    );
};